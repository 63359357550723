import './form-group.css'

export const FormGroup = ({questionTitle, hint, children}) => {
    return <div className={'form-group-wrapper'}>
        {
            [
                <div key={`${questionTitle}-question`} className={'q-title'}>{questionTitle}</div>,
                <div key={`${questionTitle}-hint`} className={'q-hint'} hidden={!hint}>{hint}</div>,
                children.map((child) =>
                    <div key={`${child.key}-form-group-wrapper`}>
                        {child}
                    </div>
                )
            ]
        }
    </div>
}