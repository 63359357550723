import './App.css';
import { useState } from "react";
import { TextInput } from "./components/text-input";
import { TextArea } from "./components/text-area";
import { Inline } from "./components/inline/inline";
import {DateInput} from "./components/date-input/date-input";
import {FormGroup} from "./components/form-group/form-group";
import {Multiselect} from "./components/multiselect/multiselect";
import navLogo from './nav-logo.png'
import axios from "axios";
import {HealthAims} from "./questions/health_aims";
import {HealthQuestionnaire} from "./questions/health_questionnaire";
import {GeneralInformation} from "./questions/general_information";
import {PilatesInformationConsent} from "./questions/pilates_information_consent";
import {PrivacyNoticeConsent} from "./questions/privacy_notice_consent";
import {Settings} from "./helpers/settings";
function RegistrationApp() {
    const [form, setForm] = useState({
        dob: {day: 1, month: 1, year: 1900}
    })

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const [submitted, setSubmitted] = useState(false)

    const updateForm = (key, value) => {
        setError(null)
        setForm({...form, [key]: value})
    }

    const currentValue = (name) => form[name]

    const valueContains = (name, value) => {
        return currentValue(name) ? currentValue(name).includes(value) : false
    }

    const emailValidation = (value) => /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value)
    const numericalInputValidator = (value) => (/^[+ \d]+$/.test(value) || value === '')

    return (
        <>
        <div className={"form-container"}>
            <nav>
                <h1>EMS Pilates Registration Form</h1>
                <img src={navLogo} alt={'logo'} width={80}/>
            </nav>
            <p>
                Please complete this questionnaire prior to attending your first Pilates session.
                Information is treated confidentially. <a href="https://static.emspilates.co.uk/participants-privacy-notice.pdf" target="_blank">
                    (Click here to see EMS Pilates participant privacy notice)
                </a>
            </p>
            <FormGroup
                questionTitle={'Contact Details'}
                children={
                    [
                        <Inline
                            key={"contact.details.name"}
                            children={
                                [
                                    <TextInput
                                        key={"title"}
                                        name="title"
                                        title="Title"
                                        value={currentValue}
                                        onChange={updateForm}
                                        weight={1}
                                    />,
                                    <TextInput
                                        key={"firstName"}
                                        name="firstName"
                                        title="First Name"
                                        value={currentValue}
                                        onChange={updateForm}
                                        weight={3}
                                    />,
                                    <TextInput
                                        key={"lastName"}
                                        name="lastName"
                                        title="Last Name"
                                        value={currentValue}
                                        onChange={updateForm}
                                        weight={3}
                                    />
                                ]
                            }
                        />,
                        <TextInput
                            key={"email"}
                            name="email"
                            title="Email"
                            value={currentValue}
                            onChange={updateForm}
                            isValid={emailValidation}
                        />,

                    ]
                }
            />
            <FormGroup
                questionTitle={"Date of Birth"}
                children={
                    [
                        <DateInput
                            key={"dob"}
                            name={"dob"}
                            value={currentValue}
                            onChange={updateForm}
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"Address"}
                children={
                    [
                        <TextInput
                            key={"addressLine1"}
                            name="addressLine1"
                            title="Address Line 1"
                            value={currentValue}
                            onChange={updateForm}
                        />,
                        <TextInput
                            key={"addressLine2"}
                            name="addressLine2"
                            title="Address Line 2"
                            value={currentValue}
                            onChange={updateForm}
                        />,
                        <TextInput
                            key={"addressCity"}
                            name="addressCity"
                            title="Town or City"
                            value={currentValue}
                            onChange={updateForm}
                        />,
                        <TextInput
                            key={"addressCounty"}
                            name="addressCounty"
                            title="County"
                            value={currentValue}
                            onChange={updateForm}
                        />,
                        <TextInput
                            key={"addressPostcode"}
                            name="addressPostcode"
                            title="Postcode"
                            value={currentValue}
                            onChange={updateForm}
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"Telephone"}
                children={
                    [
                        <TextInput
                            key={"telMobile"}
                            name="telMobile"
                            title="Mobile"
                            value={currentValue}
                            onChange={updateForm}
                            validateChange={numericalInputValidator}
                        />,
                        <TextInput
                            key={"telHome"}
                            name="telHome"
                            title="Home"
                            value={currentValue}
                            onChange={updateForm}
                            validateChange={numericalInputValidator}
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"GP Details"}
                children={
                    [
                        <TextInput
                            key={"gpName"}
                            name="gpName"
                            title="GP Name (if known)"
                            value={currentValue}
                            onChange={updateForm}
                        />,
                        <TextInput
                            key={"gpPractice"}
                            name="gpPractice"
                            title="Registered GP Practice"
                            value={currentValue}
                            onChange={updateForm}
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"Emergency Contact"}
                children={
                    [
                        <TextInput
                            key={"emergencyContactName"}
                            name="emergencyContactName"
                            title="Name"
                            value={currentValue}
                            onChange={updateForm}
                        />,
                        <TextInput
                            key={"emergencyContactHomeNumber"}
                            name="emergencyContactHomeNumber"
                            title="Home Telephone Number"
                            value={currentValue}
                            onChange={updateForm}
                            validateChange={numericalInputValidator}
                        />,
                        <TextInput
                            key={"emergencyContactMobileNumber"}
                            name="emergencyContactMobileNumber"
                            title="Mobile Telephone Number"
                            value={currentValue}
                            onChange={updateForm}
                            validateChange={numericalInputValidator}
                        />
                    ]
                }
            />
            <hr/>
            <h2>Pilates Aims</h2>
            <FormGroup
                questionTitle={"Why have you decided to commence Pilates?"}
                children={
                    [
                        <TextArea
                            key={"aimsDecision"}
                            name="aimsDecision"
                            value={currentValue}
                            onChange={updateForm}
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"Which aspects of your health would you like to concentrate on?"}
                hint={"Choose all which apply."}
                children={
                    [
                        <Multiselect
                            key={"aimsConcentration"}
                            name={'aimsConcentration'}
                            value={currentValue}
                            onChange={updateForm}
                            children={HealthAims}
                            allowMultiple={true}
                        />,
                        <TextArea
                            key={"aimsOtherDescription"}
                            name={"aimsOtherDescription"}
                            placeholder={"Other..."}
                            value={currentValue}
                            onChange={updateForm}
                            isHidden={ !valueContains('aimsConcentration', 'aimsOther') }
                        />
                    ].flatMap(e => e)
                }
            />
            <FormGroup
                questionTitle={"What are the main aims you hope to achieve with Pilates?"}
                children={
                    [
                        <TextArea
                            key={"aimsMain"}
                            name="aimsMain"
                            title=""
                            value={currentValue}
                            onChange={updateForm}
                        />
                    ]
                }
            />
            <hr/>
            <h2>Lifestyle</h2>
            <FormGroup
                questionTitle={"Occupation"}
                children={
                    [
                        <TextInput
                            key={"lifestyleOccupation"}
                            name={"lifestyleOccupation"}
                            title={"Occupation"}
                            value={currentValue}
                            onChange={updateForm}
                        />,
                        <div
                            style={{marginTop: 10}}
                            key={`lifestyle.hasProlongedPostures-question`}
                            className={'q-title'}
                        >
                            Does your occupation involve repetitive movements or prolonged postures?
                        </div>,
                        <Multiselect
                            key={"lifestyleHasProlongedPostures"}
                            name={"lifestyleHasProlongedPostures"}
                            value={currentValue}
                            onChange={updateForm}
                            children={
                                [
                                    {"id": "lifestyleHasProlongedPostures.yes", "title": "Yes"},
                                    {"id": "lifestyleHasProlongedPostures.no", "title": "No"}
                                ]
                            }
                            allowMultiple={false}
                        />,
                        <TextArea
                            key={"lifestyleProlongedPosturesReason"}
                            name={"lifestyleProlongedPosturesReason"}
                            placeholder={"Please explain further"}
                            value={currentValue}
                            onChange={updateForm}
                            isHidden={ !valueContains('lifestyleHasProlongedPostures', 'lifestyleHasProlongedPostures.yes') }
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"What sport / hobbies are you involved in?"}
                children={
                    [
                        <TextArea
                            key={"lifestyleSportsHobbies"}
                            name={"lifestyleSportsHobbies"}
                            title={"What sport / hobbies are you involved in?"}
                            value={currentValue}
                            onChange={updateForm}
                        />
                    ]
                }
            />
            <hr/>
            <h2>Health Questionnaire</h2>
            <FormGroup
                questionTitle={'Are you currently experiencing any of the following conditions:'}
                children={
                    HealthQuestionnaire.map((option) => {
                        return <div style={{marginBottom:40}} key={option.id}>
                            <p key={`${option.id}-question-title`}>{option.name}</p>
                            <Multiselect
                                key={`${option.id}-multiselect`}
                                name={option.id}
                                value={currentValue}
                                onChange={updateForm}
                                children={
                                    [
                                        {"id": `${option.id}.yes`, "title": "Yes"},
                                        {"id": `${option.id}.no`, "title": "No"}
                                    ]
                                }
                                allowMultiple={false}
                            />
                            <TextArea
                                key={`${option.id}Detail`}
                                name={`${option.id}Detail`}
                                placeholder={"Please explain futher"}
                                value={currentValue}
                                onChange={updateForm}
                                isHidden={!valueContains(option.id, `${option.id}.yes`)}
                            />
                        </div>
                    })
                }
            />
            <FormGroup
                questionTitle={"Are you pregnant?"}
                children={
                    [
                        <Multiselect
                            key={'healthPregnant'}
                            name={'healthPregnant'}
                            value={currentValue}
                            onChange={updateForm}
                            children={
                                [
                                    {"id": `healthPregnant.yes`, "title": "Yes"},
                                    {"id": `healthPregnant.no`, "title": "No"}
                                ]
                            }
                            allowMultiple={false}
                        />,
                        <TextInput
                            key={"healthPregnancyWeeks"}
                            name={"healthPregnancyWeeks"}
                            title={"How many weeks pregnant are you?"}
                            value={currentValue}
                            onChange={updateForm}
                            isHidden={!valueContains('healthPregnant', 'healthPregnant.yes')}
                        />,
                        <p
                            key={'health.pregnancy.complications.question-title'}
                            className={'q-title'}
                            hidden={!valueContains('healthPregnant', 'healthPregnant.yes')}
                            style={{marginTop: 40}}
                        >
                            Have you had any complications with your pregnancy?
                        </p>,
                        <Multiselect
                            key={'healthPregnancyComplications'}
                            name={'healthPregnancyComplications'}
                            value={currentValue}
                            onChange={updateForm}
                            children={
                                [
                                    {"id": `healthPregnancyComplications.yes`, "title": "Yes"},
                                    {"id": `healthPregnancyComplications.no`, "title": "No"}
                                ]
                            }
                            allowMultiple={false}
                            isHidden={!valueContains('healthPregnant', 'healthPregnant.yes')}
                        />,
                        <TextArea
                            key={"healthPregnancyComplicationsDescription"}
                            name={"healthPregnancyComplicationsDescription"}
                            placeholder={"Please explain further"}
                            value={currentValue}
                            onChange={updateForm}
                            isHidden={
                                !(valueContains('healthPregnancyComplications', 'healthPregnancyComplications.yes') &&
                                    valueContains('healthPregnant', 'healthPregnant.yes'))
                            }
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"Have you ever had an episode of low back pain?"}
                children={
                    [
                        <Multiselect
                            key={'healthLowerbackPain'}
                            name={'healthLowerbackPain'}
                            value={currentValue}
                            onChange={updateForm}
                            children={
                                [
                                    {"id": `healthLowerbackPain.yes`, "title": "Yes"},
                                    {"id": `healthLowerbackPain.no`, "title": "No"}
                                ]
                            }
                            allowMultiple={false}
                        />,
                        <TextInput
                            key={"healthLowerbackPainCount"}
                            name={"healthLowerbackPainCount"}
                            title={"How many episodes?"}
                            value={currentValue}
                            onChange={updateForm}
                            isHidden={!valueContains('healthLowerbackPain', 'healthLowerbackPain.yes')}
                        />
                    ]
                }
            />
            <FormGroup
                questionTitle={"Have you had any recent injuries or surgery?"}
                children={
                    [
                        <Multiselect
                            key={"healthRecentInjuries"}
                            name={"healthRecentInjuries"}
                            value={currentValue}
                            onChange={updateForm}
                            children={
                                [
                                    {"id": `healthRecentInjuries.yes`, "title": "Yes"},
                                    {"id": `healthRecentInjuries.no`, "title": "No"}
                                ]
                            }
                            allowMultiple={false}
                        />,
                        <TextArea
                            key={"healthRecentInjuriesDescription"}
                            name={"healthRecentInjuriesDescription"}
                            placeholder={"Please explain further"}
                            value={currentValue}
                            onChange={updateForm}
                            isHidden={!valueContains('healthRecentInjuries', 'healthRecentInjuries.yes')}
                        />
                    ]
                }
            />
            <hr/>
            <h2>Medical History</h2>
            <FormGroup
                questionTitle={
                    "Please disclose any known diagnosed conditions or any current ongoing medical investigations." +
                    " (Eg. Asthma, Osteoarthritis, Osteoporosis, Diabetes, Cancer, Chronic Fatigue, Any Aches or Pains, " +
                    "Current Emotional Health & Wellbeing, Upcoming Medical Appointments or Awaiting Investigations…)"
                }
                children={
                    [
                        <TextArea
                            key={"healthMedicalHistory"}
                            name={"healthMedicalHistory"}
                            value={currentValue}
                            onChange={updateForm}
                        />
                    ]
                }
            />
            <hr/>
            <h2>General Information</h2>
            <>
                {GeneralInformation().map((p, index) => <p key={`general-information-${index}`}>{p}</p>)}
                <Multiselect
                    key={"responsibilityConsent"}
                    name={"responsibilityConsent"}
                    value={currentValue}
                    onChange={updateForm}
                    children={
                        [
                            {"id": `responsibilityConsent.yes`, "title": "I understand and consent"}
                        ]
                    }
                    allowMultiple={true}
                />
            </>
            <hr/>
            <h2>Pilates Participation Informed Consent</h2>
            <>
                {PilatesInformationConsent().map((p, index) => <p key={`information-${index}`}>{p}</p>)}
                <Multiselect
                    key={"informedConsent"}
                    name={"informedConsent"}
                    value={currentValue}
                    onChange={updateForm}
                    children={
                        [
                            {"id": `informedConsent.yes`, "title": "I understand and consent"}
                        ]
                    }
                    allowMultiple={true}
                />
            </>
            <hr/>
            <h2>EMS Pilates Participant Privacy Notice Consent</h2>
            <>
                {PrivacyNoticeConsent().map((p, index) => <p key={`privacy-notice-${index}`} dangerouslySetInnerHTML={{__html: p}}/>)}
            </>
            <div className={"footer"}>
                <div className={"footer-center"}>
                    {!submitted && (
                        <>
                            {!submitting && (
                                <button id={"submit"} onClick={() => {
                                    if (submitting) {
                                        return
                                    }
                                    setError(false)
                                    setSubmitting(true)
                                    axios.post(
                                        `${Settings.host}/registration`,
                                        {data: form}
                                    )
                                        .then((response) => {
                                            if (response.data.statusCode === 200) {
                                                setSubmitted(true)
                                            } else {
                                                setError(response.data.body)
                                            }
                                        })
                                        .catch((error) => {
                                            if (!!error.response &&
                                                !!!error.response.data) {
                                                setError(error.response.data)
                                            } else {
                                                setError("There was an error submitting your form. Please try again.")
                                            }
                                        })
                                        .finally(() => {
                                            setSubmitting(false)
                                        })
                                }}>
                                    Submit
                                </button>
                            )}
                            {submitting && (
                                <button id={"submit"}>
                                    Please Wait
                                </button>
                            )}
                        </>
                    )}
                    {submitted && (
                        <p>Thanks for completing.</p>
                    )}
                </div>
            </div>
            {!!error && (<p className={"error"}>{error}</p>)}
        </div>
        </>
    );
}

export default RegistrationApp;
