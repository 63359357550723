export const TextArea = (props) => {
    let isValid
    if (props.isValid) {
        isValid = props.isValid(props.value(props.name))
    } else {
        isValid = !!props.value(props.name)
    }

    return <textarea
        className={isValid ? 'completed' : ''}
        key={props.name}
        id={props.name}
        name={props.name}
        placeholder={props.placeholder ? props.placeholder : 'Enter here'}
        value={props.value(props.name)}
        onChange={event => props.onChange(props.name, event.target.value)}
        hidden={props.isHidden}
    />
}