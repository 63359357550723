import './inline.css'

export const Inline = ({children}) => {
    const elementWeight = (child) => { return child.props.weight ? child.props.weight : 1 }
    
    return <div className={'inline-wrapper'}>
        {
            children.map((child) =>
                <div key={`${child.key}-wrapper`} style={{flex: elementWeight(child)}}>
                    {child}
                </div>
            )
        }
    </div>
}