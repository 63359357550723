import {Inline} from "../inline/inline";
import {useState} from "react";
import {DropdownInput} from "../dropdown-input";
import './date-input.css'

const days = [...Array(31).keys()].map((value => {
    return {
        id: value + 1,
        name: value + 1
    }
}))
const years = [...Array(new Date().getFullYear() - 1900).keys()].map((value => {
    return {
        id: value + 1900,
        name: value + 1900
    }
}))
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
].map((month, index) => {
    return { id: index + 1, name: month }
})

export const DateInput = (props) => {
    const [date, setDate] = useState(props.value(props.name))

    const updateDate = (key, value) => {
        const updatedDate = {...date, [key]: parseInt(value)}
        if (updatedDate !== date) {
            props.onChange(props.name, updatedDate)
        }
        setDate(updatedDate)
    }

    const isDateValid = () => {
        const dateObj = new Date(date.year, date.month - 1, date.day)
        return dateObj.getDate() === date.day &&
            dateObj.getMonth() === date.month - 1 &&
            dateObj.getFullYear() === date.year
    }

    return <div className={`${isDateValid() ? '' : 'invalid'} date-input`}>
        <Inline
            children={
                [
                    <DropdownInput
                        key={`${props.name}.day`}
                        name={`${props.name}.day`}
                        value={() => date.day ? date.day : ''}
                        onChange={(_, value) => updateDate('day', value)}
                        weight={1}
                        options={days}
                    />,
                    <DropdownInput
                        key={`${props.name}.month`}
                        name={`${props.name}.month`}
                        title="Month"
                        value={() => date.month ? date.month : ''}
                        onChange={(_, value) => updateDate('month', value)}
                        weight={4}
                        options={months}
                    />,
                    <DropdownInput
                        key={`${props.name}.year`}
                        name={`${props.name}.year`}
                        title="Year"
                        value={() => date.year ? date.year : ''}
                        onChange={(_, value) => updateDate('year', value)}
                        weight={2}
                        options={years}
                    />
                ]
            }
        />
    </div>
}