export const TextInput = (props) => {
    let isValid
    if (props.isValid) {
        isValid = props.isValid(props.value(props.name))
    } else {
        isValid = !!props.value(props.name)
    }

    return <input
        className={isValid ? 'completed' : ''}
        key={props.name}
        id={props.name}
        name={props.name}
        title={props.title}
        placeholder={props.title}
        type="text"
        value={props.value(props.name) ? props.value(props.name) : ''}
        onChange={event => {
            const value = event.target.value
            if (props.validateChange && !props.validateChange(value)) {
                return
            }
            props.onChange(props.name, event.target.value)
        }}
        hidden={props.isHidden}
        style={props.style}
    />
}