export const DropdownInput = ({name, value, onChange, options}) => {
    return <select
        key={name}
        id={name}
        name={name}
        value={value(name)}
        onChange={event => onChange(name, event.target.value)}
    >
        {
            options.map((e) =>
                <option key={`name-${e.id}`} value={e.id}>{e.name}</option>
            )
        }
    </select>
}