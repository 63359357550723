import './multiselect.css'

export const MultiselectChip = (props) => {
    return <button
        className={`multiselect-chip ${props.selected ? 'selected' : ''}`}
        onClick={() => {
            props.onClick(props.id)
        }}
        hidden={props.isHidden}
    >
        {props.name}
    </button>
}