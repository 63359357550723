import {MultiselectChip} from "./multiselect-chip";
import './multiselect.css'

export const Multiselect = (props) => {
    const selected = (id) => props.value(props.name) ? props.value(props.name).includes(id) : false
    return <div
        key={`${props.name}-multi-select-wrapper`}
        className={'multiselect'}
        hidden={props.isHidden}
    >
        {
            props.children.map((e) => {
                return <MultiselectChip
                key={e.id}
                name={e.title}
                selected={selected(e.id)}
                onClick={() => {
                    if (props.allowMultiple) {
                        let currentValue = props.value(props.name) ? [...props.value(props.name)] : []
                        if (!currentValue.includes(e.id)) {
                            currentValue.push(e.id)
                        } else {
                            currentValue = currentValue.filter((element) => element !== e.id)
                        }
                        props.onChange(props.name, currentValue)
                    } else {
                        props.onChange(props.name, [e.id])
                    }
                }}
                isHidden={props.isHidden}
                />
            })
        }
    </div>
}