export const HealthQuestionnaire = [
    {id: "healthLowBackPain", name: "Low back pain or Pelvic pain"},
    {id: "healthSpinalCondition", name: "Any Spinal condition"},
    {id: "healthOrthopaedicCondition", name: "Any Orthopaedic condition"},
    {id: "healthRheumatologicalCondition", name: "Any Rheumatological condition"},
    {id: "healthCardiacProblems", name: "Cardiac / Respiratory Problems"},
    {id: "healthHighLowBloodPressure", name: "High / low blood pressure"},
    {id: "healthEpilepsy", name: "Epilepsy"},
    {id: "healthDizziness", name: "Any Dizziness or Balance issues"},
    {id: "healthOther", name: "Any other known reason affecting Physical Activity"},
]