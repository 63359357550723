export const GeneralInformation = () => (
    [
        "The Pilates programme will begin at a low level and" +
        " will be advanced in stages depending on your fitness level." +
        " It is important for you to understand you are entitled to" +
        " stop exercising whenever you wish. It is important to inform" +
        " the Physiotherapist during the class if you experience any" +
        " pain or discomfort.",
        "Whilst every care will be taken to ensure your safety," +
        " it is impossible to predict the body’s exact response to exercise." +
        " It is important you provide the correct health information on the" +
        " registration form to minimise any risk. If identified by the" +
        " Physiotherapist prior to starting the Pilates classes it may be" +
        " appropriate to consult your GP before starting any new exercise routine.",
        "If you feel unwell or have any new injuries / illnesses, please inform the" +
        " Physiotherapist prior to the start of each Pilates class.",
        "You are welcome to bring your own equipment to the Pilates classes. However" +
        " it is your responsibility to maintain the upkeep of your own equipment to ensure" +
        " safety of use within the Pilates classes."
    ]
)